import React, { useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar";
import { useAuth } from "../AuthContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import iziToast from "izitoast";
import axios from "axios";

const UserDocVerify = () => {
  const navigate = useNavigate();
  const { apipath } = useAuth();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    user_name: "",
    user_relation: "",
    panImg: "",
    panBackImg: "",
    pan_card_status: "",
    drivingLicenseImg: "",
    drivingLicenseBackImg: "",
    drivingLicStatus: "",
    passportImg: "",
    passportBackImg: "",
    passportStatus: "",
    visaImg: "",
    visaBackImg: "",
    visaStatus: "",
    aadhaarImg: "",
    aadhaarBackImg: "",
    aadhaarStatus: "",
    bankAcImg: "",
    bankAcBackImg: "",
    bankAcStatus: "",
    panCardFrontPreview: null,
    panCardBackPreview: null,
    drivingLicenseFrontPreview: null,
    drivingLicenseBackPreview: null,
    passportFrontPreview: null,
    passportBackPreview: null,
    visaFrontPreview: null,
    visaBackPreview: null,
    aadhaarFrontPreview: null,
    aadhaarBackPreview: null,
    bankAcFrontPreview: null,
    bankAcBackPreview: null,
  });

  const handleUploadButtonClick = (inputId) => {
    document.getElementById(inputId).click();
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      const file = files[0];
      setFormData({
        ...formData,
        [name]: file,
        [`${name}Preview`]: URL.createObjectURL(file),
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    const fetchDriverDetails = async () => {
      try {
        const response = await axios.get(
          `${apipath}/document/details/${id}`
        );
        const data = response.data.documentFiles;

        setFormData((prevData) => ({
          ...prevData,
          user_name: data.user_name,
          user_relation: data.user_relation,
          pan_card_status: data.pan_card_status === "Approved",
          drivingLicStatus: data.driving_license_status === "Approved",
          passportStatus: data.passport_status === "Approved",
          visaStatus: data.visa_status === "Approved",
          aadhaarStatus: data.aadhaar_status === "Approved",
          bankAcStatus: data.bank_ac_status === "Approved",
          panImg: data.pan_card_front_img || "",
          panBackImg: data.pan_card_back_img || "",
          drivingLicenseImg: data.driving_license_front_img || "",
          drivingLicenseBackImg: data.driving_license_back_img || "",
          passportImg: data.passport_front_img || "",
          passportBackImg: data.passport_back_img || "",
          visaImg: data.visa_front_img || "",
          visaBackImg: data.visa_back_img || "",
          aadhaarImg: data.aadhaar_front_img || "",
          aadhaarBackImg: data.aadhaar_back_img || "",
          bankAcImg: data.bank_ac_front_img || "",
          bankAcBackImg: data.bank_ac_back_img || "",
        }));
      } catch (error) {
        console.error("Error fetching driver profile details:", error);
      }
    };

    fetchDriverDetails();
  }, [apipath, id]);

  const handleToggleStatus = async (field) => {
    try {
      setIsLoading(true);

      // Toggle the current field's status
      const updatedStatus = !formData[field];

      // Prepare the data for API request
      const apiField = field.replace(/([A-Z])/g, "_$1").toLowerCase(); // Convert camelCase to snake_case
      const updateData = {
        [apiField]: updatedStatus ? "Approved" : "Pending", // Adjust this as per your API requirement
      };

      console.log("Updating with data:", updateData);

      // Send PUT request to update the status
      const response = await axios.put(`${apipath}/document/details/${id}`, updateData);

      console.log("response.data.response : ", response.data.response);

      if (response.status === 200) {
        // Update the local state after successful response
        setFormData((prevData) => ({
          ...prevData,
          [field]: updatedStatus,
        }));

        iziToast.success({
          message: "Document status updated successfully",
          position: "topCenter",
        });
      } else {
        iziToast.error({
          message: "Document status update failed",
          position: "topCenter",
        });
      }
    } catch (error) {
      console.error(`Error updating ${field} status:`, error.response || error.message);

      iziToast.error({
        message: "An error occurred while updating the status",
        position: "topCenter",
      });
    } finally {
      setIsLoading(false);
    }
  };



  // const handleDownload = (url, filename) => {
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.download = filename;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const handleOpenInNewTab = (url) => {
    window.open(url, "_blank");
  };


  const documentFields = [
    { label: "PAN Card", frontName: "panImg", backName: "panBackImg", previewFront: "panCardFrontPreview", previewBack: "panCardBackPreview", statusField: "pan_card_status" },
    { label: "Driving License", frontName: "drivingLicenseImg", backName: "drivingLicenseBackImg", previewFront: "drivingLicenseFrontPreview", previewBack: "drivingLicenseBackPreview", statusField: "drivingLicStatus" },
    { label: "Passport", frontName: "passportImg", backName: "passportBackImg", previewFront: "passportFrontPreview", previewBack: "passportBackPreview", statusField: "passportStatus" },
    { label: "Visa", frontName: "visaImg", backName: "visaBackImg", previewFront: "visaFrontPreview", previewBack: "visaBackPreview", statusField: "visaStatus" },
    { label: "Aadhaar", frontName: "aadhaarImg", backName: "aadhaarBackImg", previewFront: "aadhaarFrontPreview", previewBack: "aadhaarBackPreview", statusField: "aadhaarStatus" },
    { label: "Bank Account", frontName: "bankAcImg", backName: "bankAcBackImg", previewFront: "bankAcFrontPreview", previewBack: "bankAcBackPreview", statusField: "bankAcStatus" },
  ];

  return (
    <>
      <AdminSidebar />
      <div className="home-main-content">
        <div className="container-fluid pt-4">
          <div className="toggle-sidebar" style={{ display: "none" }}>
            <i className="bi bi-menu"></i>
          </div>

          <div className="container pt-3">
            <div className="row top-barcolumn">
              <h5 className="heading">Edit and verify user documents</h5>
              <div className="row nav-column outer">
                <form className="row">
                  {[{ label: "Full Name", name: "user_name" }, { label: "Relation Name", name: "user_relation" }].map(
                    (field) => (
                      <div className="col-lg-6 mb-3" key={field.name}>
                        <label className="form-label">{field.label}</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={`Enter ${field.label.toLowerCase()}`}
                          name={field.name}
                          value={formData[field.name]}
                          onChange={handleChange}
                          readOnly
                        />
                      </div>
                    )
                  )}
                  {documentFields.map((doc) => (
                    <div className="col-lg-6 mb-3" key={doc.frontName}>
                      <div className="change-profile pt-4 pb-4">
                        <img
                          src={formData[doc.previewFront] || formData[doc.frontName] || `${process.env.PUBLIC_URL}/images/user-icon.png`}
                          width={90}
                          height={90}
                          style={{ borderRadius: "35px" }}
                          alt={`${doc.label} Front`}
                          onClick={() => handleUploadButtonClick(doc.frontName)}
                        />
                        <img
                          src={formData[doc.previewBack] || formData[doc.backName] || `${process.env.PUBLIC_URL}/images/user-icon.png`}
                          width={90}
                          height={90}
                          style={{ borderRadius: "35px" }}
                          alt={`${doc.label} Back`}
                          onClick={() => handleUploadButtonClick(doc.backName)}
                        />
                      </div>
                      {/* <i
                          className="bi bi-eye ms-5"
                          onClick={() =>
                            handleDownload(formData[doc.frontName], `${doc.label}_front.png`)
                          } target="_blank"
                        ></i>
                        <i
                          className="bi bi-eye-fill ms-5"
                          onClick={() =>
                            handleDownload(formData[doc.backName], `${doc.label}_back.png`)
                          } target="_blank"
                        ></i> */}

                      <i
                        className="bi bi-eye-fill point ms-5"
                        onClick={() => handleOpenInNewTab(formData[doc.frontName])}
                        target="_blank"
                      ></i>
                      <i
                        className="bi bi-eye-fill point ms-5"
                        onClick={() => handleOpenInNewTab(formData[doc.backName])}
                        target="_blank"
                      ></i>

                      <div>
                        <label className="form-label">{doc.label} Status</label>
                        <br />
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={formData[doc.statusField]}
                            onChange={() => handleToggleStatus(doc.statusField)}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <input
                        id={doc.frontName}
                        // type="file"
                        name={doc.frontName}
                        onChange={handleChange}
                        style={{ display: "none" }}
                      />
                      <input
                        id={doc.backName}
                        // type="file"
                        name={doc.backName}
                        onChange={handleChange}
                        style={{ display: "none" }}
                      />
                    </div>
                  ))}
                </form>
                <div className="text-center">
                  <Link to="/documents-details">
                    <button className="btn btn-primary col-3">Back</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDocVerify;
