import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import AdminSidebar from "./AdminSidebar";
import { useAuth } from "../AuthContext";
import axios from "axios";
import moment from "moment";

const DocumentsDetails = () => {
  const dataTableRef = useRef();
  const [show, setShow] = useState(false);
  const [documentFilesIdToDelete, setDocumentFilesIdToDelete] = useState(null);

  const handleShow = (id) => {
    setDocumentFilesIdToDelete(id);
    setShow(true);
  };
  const handleClose = () => setShow(false);

  const [documentFiles, setDocumentFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { apipath ,filepath } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchDocumentFiles();
    if (!loading && documentFiles.length > 0) {
      $(dataTableRef.current).DataTable();
    }
  }, [loading, documentFiles]);

  const fetchDocumentFiles = async () => {
    try {
      const response = await axios.get(`${apipath}/document/details`);

      setDocumentFiles(response.data.documentFiles);
      console.log("response.data.documentFiles :",response.data.documentFiles);
      
      setLoading(false);
    } catch (error) {
      console.error("Error fetching documentFiles:", error);
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      await axios.delete(`${apipath}/document/details/${documentFilesIdToDelete}`);
      await fetchDocumentFiles(); // Refresh users after deletion
      setShow(false);
      setIsLoading(false);
    } catch (error) {
      console.error("Error deleting DocumentFiles:", error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <AdminSidebar />

      <div className="home-main-content">
        <div className="container-fluid pt-4">
          <div className="toggle-sidebar" style={{ display: "none" }}>
            <i className="bi bi-menu"></i>
          </div>

          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Delete User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="modal-content1">
                <p>Are you sure you want to delete this user?</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="modal-footer-btn" onClick={handleDelete}>
                Delete
              </button>
            </Modal.Footer>
          </Modal>

          <div className="container position-relative z-0 ">
            <div className="row foot-tablerow">
              <h5 className="heading">User Details</h5>
              <div className="col-lg-12 maintable-column box">
                <div className="container mt-4 overflow-auto">
                  <table id="datatable" className="table" ref={dataTableRef}>
                    {/* <table id="datatable" className="table" > */}
                    <thead>
                      <tr>
                        <th>S. No.</th>
                        <th>User name</th>
                        <th>User relation</th>
                        <th>Aadhaar</th>
                        <th>Pan Card</th>
                        <th>Driving License</th>
                        <th>Passport</th>
                        <th>VISA</th>
                        <th>Bank A/C</th>
                        <th>Date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {documentFiles.map((documentFile, index) => (
                        <tr key={documentFile._id}>
                          <td className="text-center">{index + 1}</td>
                          <td>{documentFile.user_name} </td>
                          <td>{documentFile.user_relation}</td>
                          <td> <img src= {documentFile.aadhaar_front_img} alt="Aadhaar" style={{ display: 'flex', objectFit: 'cover',borderRadius: '50%', width: '65px', height: '65px' }} /></td>
                          <td> <img src= {documentFile.pan_card_front_img} alt="PanCard" style={{ display: 'flex', objectFit: 'cover',borderRadius: '50%', width: '65px', height: '65px' }} /></td>
                          <td> <img src= {documentFile.driving_license_front_img} alt="License" style={{ display: 'flex', objectFit: 'cover',borderRadius: '50%', width: '65px', height: '65px' }} /></td>
                          <td> <img src= {documentFile.passport_front_img} alt="Passport" style={{ display: 'flex', objectFit: 'cover',borderRadius: '50%', width: '65px', height: '65px' }} /></td>
                          <td> <img src= {documentFile.visa_front_img} alt="VISA" style={{ display: 'flex', objectFit: 'cover',borderRadius: '50%', width: '65px', height: '65px' }} /></td>
                          <td> <img src= {documentFile.bank_ac_front_img} alt="BancA/C" style={{ display: 'flex', objectFit: 'cover',borderRadius: '50%', width: '65px', height: '65px' }} /></td>
                          <td>{moment(documentFile.createdAt).format("YYYY-MM-DD")}</td>
                          <td>
                            <Link to={`/user-doc-verify/${documentFile._id}`}>
                              <i className="fa-regular fa-pen-to-square"></i>
                            </Link>{" "}
                            &nbsp;
                            <i
                              className="fa-solid fa-trash"
                              onClick={() => handleShow(documentFile._id)}
                              style={{ cursor: "pointer" }}
                            ></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentsDetails;
