import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import iziToast from "izitoast";
import { Link } from 'react-router-dom'
import { useAuth } from '../AuthContext';
const Login = () => {
    const { apipath } = useAuth();

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });
    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    // const handleSubmit = async (event) => {
    //     event.preventDefault();

    //     try {
    //         const response = await fetch(
    //             apipath + "/login",
    //             {
    //                 method: "POST",
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                 },
    //                 body: JSON.stringify(formData)
    //             }
    //         );

    //         const result = await response.json();
    //         console.log(result.documents);

    //         if (result.success === true) {
    //             iziToast.success({
    //                 message: "Login successful",
    //                 position: "topCenter"
    //             });
    //             if (result.user.role === 'admin') {
    // //                 navigate("/admindashboard");
    //                 localStorage.setItem('admintoken', result.token);
    //                 localStorage.setItem('adminname', result.user.name);
    //             }
    //             else {
    //                 navigate("/dashboard");
                    // localStorage.setItem('userid', result.user._id);
                    // localStorage.setItem('username', result.user.name);
                    // localStorage.setItem('user_type', result.user.user_type);
                    // localStorage.setItem('documents', JSON.stringify(result.documents));
                    // localStorage.setItem('token', result.token);
    //             }
    //         } else {
    //             iziToast.error({
    //                 message: "Login failed : " + result.error,
    //                 position: "topCenter"
    //             });
    //             console.error("Login failed:", result.error);
    //         }
    //     } catch (error) {
    //         iziToast.error({
    //             message: "Error during login",
    //             position: "topCenter"
    //         });
    //         console.error("Error during login:", error);
    //     }
    // };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        try {
          const response = await fetch(apipath + "/login", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          });
    
          const result = await response.json();
          localStorage.setItem("token", result.token);
          console.log(result);
    
          if (result.success === true) {
            iziToast.success({
              message: "Login successful",
              position: "topCenter",
            });
            if (result.user.role === "admin") {
                localStorage.setItem('admintoken', result.token);
                localStorage.setItem('adminname', result.user.name);
                navigate("/admindashboard");
            } else {
                navigate("/dashboard");
                localStorage.setItem('userid', result.user._id);
                localStorage.setItem('username', result.user.name);
                localStorage.setItem('user_type', result.user.user_type);
                localStorage.setItem('documents', JSON.stringify(result.documents));
                localStorage.setItem('token', result.token);
    
            //   const redirectTo = localStorage.getItem("redirectAfterLogin") || "/";
            //   localStorage.removeItem("redirectAfterLogin");
            //   navigate(redirectTo);
            }
          } else {
            iziToast.error({
              message: "Login failed : " + result.error,
              position: "topCenter",
            });
            console.error("Login failed:", result.error);
          }
        } catch (error) {
          iziToast.error({
            message: "Error during login",
            position: "topCenter",
          });
          console.error("Error during login:", error);
        }
      };

    const handleChange = (event) => {
        const { name, value, type } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row login-row justify-content-center">
                    <div className="col-lg-6 login-left-column">
                        <div className="">
                            <img src="images/logo.png" width={150} height={38} alt="" />
                            <h4>Few click away on <br /> KYC.</h4>
                            <p>Start your KYC in minutes.  <br />
                                save time and money</p>
                            <div className="row justify-content-center">
                                <div className="login-img col-lg-6">
                                    <img src="images/login.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="right-column">
                                    <h4>Login <img src="images/hand.png" alt="" /> </h4>
                                    <p>Thank you for get back to CKYC.</p>
                                    <form action="" onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <label htmlFor="">Email</label>
                                            <input type="email" required name="email" onChange={handleChange} className="form-control" />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="">Password</label>
                                            <div className="password-input">
                                                <input
                                                    type={passwordVisible ? "text" : "password"}
                                                    className="form-control"
                                                    placeholder=""
                                                    name="password"
                                                    required
                                                    onChange={handleChange}
                                                />
                                                <i
                                                    className={`bi bi-eye${passwordVisible ? "-slash" : ""}`}
                                                    onClick={togglePasswordVisibility}
                                                    style={{ cursor: "pointer" }}
                                                ></i>
                                            </div>
                                        </div>
                                        <div className="mb-4 remember-box">
                                            <Link to="/forget_password" className="forgot-pass"> Forgot Password </Link>
                                        </div>
                                        <div className="submit-box">
                                            <button className="btn">Submit</button>
                                            <h6 className="pt-4">Don’t have an account? <Link className="link" to="/register">Sign up</Link> </h6>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;