import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const Sidebar = () => {
  const { logout } = useAuth();
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('userid');
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const isActiveLink = (path) => {
    return location.pathname === path;
  };

  if(!token){
    logout();
  }

  return (
    <>
      <header>
        <div className="toggle" onClick={toggleSidebar}>
          <i className={isSidebarOpen ? "bi bi-x" : "bi bi-list"}></i>
        </div>
      </header>
      <section className={`dapp-side-bar-nav ${isSidebarOpen ? 'open' : ''}`}>
        <ul className="pt-4">
          <li className={isActiveLink("/dashboard") ? "active" : ""}>
            <Link to="/dashboard">
              <span className="icon"><i className="bi bi-columns-gap"></i></span>
              <span className="title">Dashboard</span>
            </Link>
          </li>
          <li className={isActiveLink("/kyc") ? "active" : ""}>
            <Link to="/kyc">
              <span className="icon"><i className="bi bi-receipt-cutoff"></i></span>
              <span className="title">KYC Process</span>
            </Link>
          </li>
          <li className={isActiveLink("/documents") ? "active" : ""}>
            <Link to={`/documents/${userId}`}>
              <span className="icon"><i class="bi bi-file-earmark"></i></span>
              <span className="title">Documents</span>
            </Link>
          </li>
          <li className={isActiveLink("/profile") ? "active" : ""}>
            <Link to="/profile">
              <span className="icon"><i class="bi bi-person"></i></span>
              <span className="title">Profile</span>
            </Link>
          </li>
          <div className='blank'></div>
          <li className={isActiveLink("/") ? "active" : ""}>
            <Link to="" onClick={logout} >
              <span className="icon"><i class="bi bi-box-arrow-right"></i></span>
              <span className="title">Logout</span>
            </Link>
          </li>
        </ul>
      </section>
      <div className="dash-nav position-fixed w-100 top-0 z-3">
    <div>
        <img src={`${process.env.PUBLIC_URL}/images/dash-logo.png`} alt="logo" />
    </div>
        <div className="profile-name">
            <div>
            <i class="bi bi-bell"></i>
            </div>
        </div>
    </div>
    </>
  );
};

export default Sidebar;
