import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const Uploaddoc = () => {
    const navigate = useNavigate();
    const uploadDoc = JSON.parse(localStorage.getItem('uploadDoc'));
    const upload_response = JSON.parse(localStorage.getItem('upload_response'));
    const doc = uploadDoc.document;
    const [docfields, setDocFields] = useState();
    const aadharextractionId = "-NytxOSrDnM7npK9uBWs";
    const panextractionId = "-Nyt7rTjXbLCLXaWnnlE";
    const btoken = "LTE5MDQwMDQ0ODM=_sk5pb23h7acyhxqom001e";
    console.log(doc);
    const [aadhaarFrontName, setAadhaarFrontName] = useState("Upload file appears here");
    const [aadhaarBackName, setAadhaarBackName] = useState("Upload file appears here");

    const url = "https://api.extracta.ai/api/v1/uploadFiles";
    const [formData, setFormData] = useState(new FormData());

    const handleFileUpload = (e, type) => {
        const file = e.target.files[0];
        if (file) {
            const fileName = file.name;
            setFormData(formData => ({ ...formData, ['files[]']: file }));
            if (type === "front") {
                setAadhaarFrontName(fileName);
            } else if (type === "back") {
                setAadhaarBackName(fileName);
            }
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        var formdata = new FormData();
        var extid = panextractionId;
        if (doc === "Aadhar") {
            extid = aadharextractionId;
        }
        const files = document.getElementsByClassName('files');
        for (const file of files) {
            if (file.files && file.files.length > 0) {
                // Access the first selected file
                const selectedFile = file.files[0];

                // Append the file to the FormData object
                formdata.append('files', selectedFile);  // Replace 'fieldName' with your actual field name
            }
        }
        formdata.append('extractionId', extid);
        const response = await axios.post(url, formdata, {
            headers: {
                'Authorization': `Bearer ${btoken}`
            },
            // Axios automatically sets the Content-Type to multipart/form-data with the boundary.
        });

        // Handling response
        console.log(response.data); // Directly return the parsed JSON response
        localStorage.setItem('upload_response', JSON.stringify(response.data));
        navigate('/detail');
    }

    const handleGetResults = async () => {
        const url = "https://api.extracta.ai/api/v1/getBatchResults";
        const fdata = {
            'extractionId':upload_response.extractionId,
            'batchId':upload_response.batchId,
        }
        const response = await axios.post(url, fdata, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${btoken}`
            }
        });
        console.log(response.data);
    }

    // if(upload_response.status === 'uploaded'){
    //     handleGetResults();
    // }

    useEffect(() => {
        if (doc === "Aadhar") {
            setFormData(formData => ({ ...formData, ['extractionId']: aadharextractionId }));
            setDocFields(<>
                <div className="col-lg-5">
                    <div className="inner-column">
                        <h3>{doc} Front Photo </h3>
                        <div className="upload-box">
                            <label className="btn" htmlFor="uploadFront">
                                <img src="images/upload.png" width={55} height={55} alt="" />
                                <input className="isVisuallyHidden files" id="uploadFront" type="file" name="files[]" onChange={(e) => handleFileUpload(e, "front")} required />
                            </label>
                            <h4>Click to Upload</h4>
                            <small>PNG, JPG</small>
                        </div>
                        <div className="result-box mt-4">
                            <img src="images/file.png" width={32} height={32} alt="" />
                            <h6>{aadhaarFrontName}</h6>
                            <i className="bi bi-check-circle-fill"></i>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="inner-column">
                        <h3>{doc} Back Photo </h3>
                        <div className="upload-box">
                            <label className="btn" htmlFor="uploadBack">
                                <img src="images/upload.png" width={55} height={55} alt="" />
                                <input className="isVisuallyHidden files" id="uploadBack" type="file" name="files[]" onChange={(e) => handleFileUpload(e, "back")} required />
                            </label>
                            <h4>Click to Upload</h4>
                            <small>PNG, JPG</small>
                        </div>
                        <div className="result-box mt-4">
                            <img src="images/file.png" width={32} height={32} alt="" />
                            <h6>{aadhaarBackName}</h6>
                            <i className="bi bi-check-circle-fill"></i>
                        </div>
                    </div>
                </div>
            </>);
        }
        else if (doc === "PAN") {
            setFormData(formData => ({ ...formData, ['extractionId']: panextractionId }));
            setDocFields(<>
                <div className="col-lg-5">
                    <div className="inner-column">
                        <h3>{doc} Photo </h3>
                        <div className="upload-box">
                            <label className="btn" htmlFor="uploadFront">
                                <img src="images/upload.png" width={55} height={55} alt="" />
                                <input className="isVisuallyHidden files" id="uploadFront" type="file" name="files[]" onChange={(e) => handleFileUpload(e, "front")} required />
                            </label>
                            <h4>Click to Upload</h4>
                            <small>PNG, JPG</small>
                        </div>
                        <div className="result-box mt-4">
                            <img src="images/file.png" width={32} height={32} alt="" />
                            <h6>{aadhaarFrontName}</h6>
                            <i className="bi bi-check-circle-fill"></i>
                        </div>
                    </div>
                </div>
            </>);
        }
        else {
            navigate("/kyc");
        }
    }, []);

    return (
        <>
            <Sidebar />

            <div className="home-main-content">

                <div className="container-fluid pt-4">
                    <div className="row">
                        <div className="col-lg-10">
                            <div className="upload-column">
                                <div>
                                    <h5>{doc}</h5>
                                    <p>Please upload your {doc} below for completing your first step of KYC.</p>
                                </div>

                                <div className="row pt-4">
                                    <form id="docform" onSubmit={handleSubmit}>
                                        {docfields}
                                        {/* <div className="col-lg-5">
                                        <div className="inner-column">
                                            <h3>Aadhaar card front photo </h3>
                                            <div className="upload-box">
                                                <label className="btn" htmlFor="uploadFront">
                                                    <img src="images/upload.png" width={55} height={55} alt="" />
                                                    <input className="isVisuallyHidden" id="uploadFront" type="file" onChange={(e) => handleFileUpload(e, "front")} required />
                                                </label>
                                                <h4>Click to Upload</h4>
                                                <small>PNG, JPG</small>
                                            </div>
                                            <div className="result-box mt-4">
                                                <img src="images/file.png" width={32} height={32} alt="" />
                                                <h6>{aadhaarFrontName}</h6>
                                                <i className="bi bi-check-circle-fill"></i>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="col-lg-5">
                                            <div className="inner-column">
                                                <h3>Aadhaar card Back photo </h3>
                                                <div className="upload-box">
                                                    <label className="btn" htmlFor="uploadBack">
                                                        <img src="images/upload.png" width={55} height={55} alt="" />
                                                        <input className="isVisuallyHidden" id="uploadBack" type="file" onChange={(e) => handleFileUpload(e, "back")} required />
                                                    </label>
                                                    <h4>Click to Upload</h4>
                                                    <small>PNG, JPG</small>
                                                </div>
                                                <div className="result-box mt-4">
                                                    <img src="images/file.png" width={32} height={32} alt="" />
                                                    <h6>{aadhaarBackName}</h6>
                                                    <i className="bi bi-check-circle-fill"></i>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="skip-next pt-5">
                                            {/* <button className="skip-btn">Skip</button> */}
                                            <button className="next-btn">Submit & Next</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default Uploaddoc;
