import React from "react";
import Sidebar from "./Sidebar";

const Dashboard = () => {

  return (
    <>
      <Sidebar />
      <div className="home-main-content">

      </div>
    </>
  );
};

export default Dashboard;