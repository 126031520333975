import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import iziToast from "izitoast";

const AdminSidebar = () => {
  const { logout } = useAuth();
  // const adminToken = localStorage.getItem("admintoken");
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  // const location = useLocation();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const isActiveLink = (path) => {
    return location.pathname === path;
  };

  // if (!adminToken) {
  //   logout();
  // }

  const location = useLocation();
 
  const adminToken = localStorage.getItem('admintoken');
  const adminname = localStorage.getItem('adminname');
  const adminemail = localStorage.getItem('adminemail');
  const admintoken = localStorage.getItem('admintoken');
  if(admintoken === null || admintoken === ""){
    navigate("/");
  }

  
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('userId');
    localStorage.removeItem('user_type');
    localStorage.removeItem('admintoken');
    localStorage.removeItem('adminname');
    localStorage.removeItem('adminId');
    iziToast.success({
      message: "Logout successful",
      position: "topCenter",
    });
    // navigate("/");
    window.location.href = "/";
  };
  return (
    <>
      <header>
        <div className="toggle" onClick={toggleSidebar}>
          <i className={isSidebarOpen ? "bi bi-x" : "bi bi-list"}></i>
        </div>
      </header>
      <section className={`dapp-side-bar-nav ${isSidebarOpen ? "open" : ""}`}>
        <ul className="pt-4">
          <li className={isActiveLink("/admindashboard") ? "active" : ""}>
            <Link to="/admindashboard">
              <span className="icon">
                <i className="bi bi-columns-gap"></i>
              </span>
              <span className="title">Dashboard</span>
            </Link>
          </li>
          <li className={isActiveLink("/user-details") ? "active" : ""}>
            <Link to="/user-details">
              <span className="icon">
                <i className="bi bi-receipt-cutoff"></i>
              </span>
              <span className="title">User Manage</span>
            </Link>
          </li>
          <li className={isActiveLink("/documents-details") ? "active" : ""}>
            <Link to="/documents-details">
              <span className="icon">
              <i class="bi bi-file-earmark"></i>
              </span>
              <span className="title">Documents Manage</span>
            </Link>
          </li>
          {/* <li className={isActiveLink("/profile") ? "active" : ""}>
            <Link to="/profile">
              <span className="icon"><i className="bi bi-columns-gap"></i></span>
              <span className="title">Profile</span>
            </Link>
          </li> */}
          <div className="blank"></div>
          <li className={isActiveLink("/") ? "active" : ""}>
            <Link onClick={handleLogout}>
              <span className="icon">
              <i class="bi bi-box-arrow-right"></i>
              </span>
              <span className="title">Logout</span>
            </Link>
          </li>
        </ul>
      </section>
      <div className="dash-nav position-fixed w-100 top-0 z-3">
      <div>
          <img src={`${process.env.PUBLIC_URL}/images/dash-logo.png`} alt="logo" />
        </div>
        <div className="profile-name">
          <div>
            <i class="bi bi-bell"></i>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSidebar;
