import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { Link, useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  const user_type = localStorage.getItem('user_type');
  // const [comp_type, setComptype] = useState('');
  const documents = JSON.parse(localStorage.getItem('documents'));
  const [company_type, setCompanytype] = useState('');
  const [formData, setFormData] = useState({
    usertype: user_type,
    companytype: "",
    document: "",
  });
  // console.log(user_type);

  const handleCompany = (company) => {
    setCompanytype(company);
    formData.companytype = company;
  }
  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked ? value : '',
    }));
  }

  useEffect(() => {
    if (user_type === "Individual") {
      setCompanytype("Individual");
      formData.companytype = "Individual";
      formData.document = "";
      }
      else if (user_type === "Others") {
        setCompanytype("Others");;
        formData.companytype = "Others";
        formData.document = "";
        }
        else {
          setCompanytype("Private Limited");
          formData.companytype = "Private Limited";
          formData.document = "";
    }
  }, []);
  useEffect(()=>{
    console.log(formData);
  },[formData]);

  const handleSubmit = () => {
    if(formData.document !== ""){
      localStorage.setItem('uploadDoc', JSON.stringify(formData));
      navigate("/upload");
    }
  }

  if (user_type === "Cooperate") {
    var complist = <>
      <div class="form-check col-lg-3 col-6">
        <input class="form-check-input" type="radio" value="Private Limited" onClick={() => { handleCompany("Private Limited") }} checked={company_type === "Private Limited"} name="flexRadioDefault" id="flexRadioDefault1" />
        <label class="form-check-label" for="flexRadioDefault1">
          Private limited company
        </label>
      </div>
      <div class="form-check col-lg-3 col-6">
        <input class="form-check-input" type="radio" value="Partnership" onClick={() => { handleCompany("Partnership") }} checked={company_type === "Partnership"} name="flexRadioDefault" id="flexRadioDefault2" />
        <label class="form-check-label" for="flexRadioDefault2">
          Partnership firm
        </label>
      </div>
      <div class="form-check col-lg-3 col-6">
        <input class="form-check-input" type="radio" value="Others" onClick={() => { handleCompany("Others") }} checked={company_type === "Others"} name="flexRadioDefault" id="flexRadioDefault3" />
        <label class="form-check-label" for="flexRadioDefault3">
          Others
        </label>
      </div>
    </>
  }
  else {
    complist = <>
      <div class="form-check col-lg-3 col-6">
        <input class="form-check-input" type="radio" value={user_type} onClick={() => { handleCompany(user_type) }} checked={company_type === user_type} name="companytype" id="flexRadioDefault1" />
        <label class="form-check-label" for="flexRadioDefault1">
          {user_type} company
        </label>
      </div>
    </>
  }
  if (documents.length < 1) {
    var mydocs = "";
    var doclist = "";
  }
  else {
    mydocs = documents.map((doc) => {
      return (
        <>
          <div class="form-check col-lg-3 col-6" style={{ display: company_type === doc.company_type ? "block" : "none" }}>
            <input class="form-check-input" type="radio" value={`${doc.document_name}`} name="document" onChange={handleChange} />
            <label class="form-check-label" for="flexCheckDefault">
              {doc.document_name}
            </label>
          </div>
        </>
      );
    });

    doclist = <>
      <h5>{company_type} Document KYC</h5>
      <p>Documents required for <span>{company_type} KYC</span> are listed <br /> below</p>

      <form class="row radio-form">
        {mydocs}
      </form>
    </>
  }

  return (
    <>
      <Sidebar />
      <div className="home-main-content">

        <div className="container-fluid pt-4">
          <div className="row">
            <div className="col-lg-10">
              <div className="select-column">
                <h4>Select Your Business Entity Type</h4>
                <form class="row radio-form">
                  {complist}
                  {/* <div class="form-check col-lg-3 col-6">
                    <input class="form-check-input" type="radio" value="Private Limited" onClick={() => { handleCompany("Private Limited") }} checked={company_type === "Private Limited"} name="flexRadioDefault" id="flexRadioDefault1" />
                    <label class="form-check-label" for="flexRadioDefault1">
                      Private limited company
                    </label>
                  </div>
                  <div class="form-check col-lg-3 col-6">
                    <input class="form-check-input" type="radio" value="Partnership" onClick={() => { handleCompany("Partnership") }} checked={company_type === "Partnership"} name="flexRadioDefault" id="flexRadioDefault2" />
                    <label class="form-check-label" for="flexRadioDefault2">
                      Partnership firm
                    </label>
                  </div>
                  <div class="form-check col-lg-3 col-6">
                    <input class="form-check-input" type="radio" value="Others" onClick={() => { handleCompany("Others") }} checked={company_type === "Others"} name="flexRadioDefault" id="flexRadioDefault3" />
                    <label class="form-check-label" for="flexRadioDefault3">
                      Others
                    </label>
                  </div> */}
                </form>
                <hr />
                <div className="check-column pt-3">
                  <form id="">
                    {doclist}
                  </form>

                </div>
                <button onClick={handleSubmit}>Upload Documents</button>
              </div>
            </div>
          </div>
        </div>

      </div>

    </>
  );
};

export default Dashboard;