import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom'
import iziToast from "izitoast";
import { useAuth } from '../AuthContext';
const Register = () => {
    const { apipath } = useAuth();

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        email: "",
        company_name: "",
        user_type: "",
        password: "",
    });
    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch(
                apipath + "/register",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(formData)
                }
            );

            const result = await response.json();
            // console.log(result);
            // navigate("/");

            // const response = await CustomerApi.getCustomerSingUp(formData);
            // const responseData = response.data;
            // history.push("/customerLogin");
            // Check the response from the server and handle accordingly
            if (result.success === true) {
                iziToast.success({
                    message: "Registration successful",
                    position: "topCenter"
                });
                // console.log("Registration successful");
                navigate("/");
            } else {
                var errors = result.error;
                if (errors.errors.name) {
                    document.getElementById('name_err').innerHTML = errors.errors.name.message;
                }
                if (errors.errors.email) {
                    document.getElementById('email_err').innerHTML = errors.errors.email.message;
                }
                if (errors.errors.company_name) {
                    document.getElementById('company_err').innerHTML = errors.errors.company_name.message;
                }
                if (errors.errors.phone) {
                    document.getElementById('phone_err').style.display = 'block';
                }
                if (errors.errors.user_type) {
                    document.getElementById('user_type_err').innerHTML = errors.errors.user_type.message;
                }
                if (errors.errors.password) {
                    document.getElementById('pass_err').innerHTML = errors.errors.password.message;
                }
                console.error("Registration failed:", result);
            }
        } catch (error) {
            iziToast.error({
                message: "Error during registration",
                position: "topCenter"
            });
            console.error("Error during registration:", error);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row login-row justify-content-center">
                    <div className="col-lg-6 login-left-column">
                        <div className="">
                            <img src="images/logo.png" width={150} height={38} alt="" />
                            <h4>Few click away on <br /> KYC.</h4>
                            <p>Start your KYC in minutes.  <br />
                                save time and money</p>
                            <div className="row justify-content-center">
                                <div className="login-img col-lg-6">
                                    <img src="images/login.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="right-column">
                                    <h4>SignUp <img src="images/hand.png" alt="" /> </h4>
                                    <p>Thank you for get back to CKYC.</p>
                                    <form action="" onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <label htmlFor="">Name</label>
                                            <input onChange={handleChange} type="text" className="form-control" name="name" required />
                                            <span id="name_err" className="text-danger"></span>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="">Email</label>
                                            <input onChange={handleChange} type="email" className="form-control" name="email" required />
                                            <span id="email_err" className="text-danger"></span>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="">Company Name</label>
                                            <input onChange={handleChange} type="text" className="form-control" name="company_name" required />
                                            <span id="company_err" className="text-danger"></span>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="">Phone Number</label>
                                            <input onChange={handleChange} type="text" className="form-control" pattern="\d*" name="phone" required maxLength={10} />
                                            <span id="phone_err" style={{'display':'none'}} className="text-danger">Enter valid 10 digit number.</span>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="">User Type</label>
                                            <select className="form-control" name="user_type" required onChange={handleChange}>
                                                <option value="">Select User Type</option>
                                                <option value="Cooperate">Cooperate</option>
                                                <option value="Individual">Individual</option>
                                                <option value="Agent">Agent</option>
                                            </select>
                                            <span id="user_type_err" className="text-danger"></span>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="">Password</label>
                                            <div className="password-input">
                                                <input onChange={handleChange}
                                                    type={passwordVisible ? "text" : "password"}
                                                    className="form-control"
                                                    placeholder=""
                                                    name="password"
                                                    required
                                                />
                                                <i
                                                    className={`bi bi-eye${passwordVisible ? "-slash" : ""}`}
                                                    onClick={togglePasswordVisibility}
                                                    style={{ cursor: "pointer" }}
                                                ></i>
                                            </div>
                                            <span id="pass_err" className="text-danger"></span>
                                        </div>
                                        <div className="submit-box">
                                            <button className="btn">Submit</button>
                                            <h6 className="pt-4">Already have an account? <Link className="link" to="/">Sign in</Link> </h6>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Register;