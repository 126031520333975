import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import { useAuth } from "../AuthContext";
import { useParams } from "react-router-dom";

const Documents = () => {
    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(true);
    const { apipath } = useAuth();
    const userId = localStorage.getItem('userid');

    useEffect(() => {
        // Fetching the document details for the user
        const fetchDocuments = async () => {
            try {
                const response = await axios.get(apipath+`/document/details/user/${userId}`);
                setDocuments(response.data.documentFiles); // Set the document files data
                setLoading(false); // Set loading to false once data is fetched
            } catch (error) {
                console.error("Error fetching documents:", error);
                setLoading(false); // Set loading to false even if there's an error
            }
        };

        fetchDocuments();
    }, []);

    if (loading) {
        return <p>Loading documents...</p>;
    }

    return (
        <>
            <Sidebar />

            <div className="home-main-content">
                <div className="container-fluid pt-4">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="upload-column">
                                <div>
                                    <h5>Submitted Documents</h5>
                                    <p>Below are your submitted documents</p>
                                </div>

                                <div className="row pt-4">
                                    {documents.map((doc, index) => (
                                        <div className="col-lg-8" key={doc._id}>
                                            <h4>Relative: {doc.user_relation}</h4>

                                            {/* Pan Card Document */}
                                            {doc.pan_card_front_img && (
                                                <div className="submit-box">
                                                    <h4>{doc.pan_card_document || "Pan Card"}</h4>
                                                    <div className="submit-doc">
                                                        <img src={doc.pan_card_front_img} alt="Pan Card Front" style={{ width: '520px', height: '280px' }} />
                                                        {doc.pan_card_back_img && <img src={doc.pan_card_back_img} alt="Pan Card Back" style={{ width: '520px', height: '280px' }} />}
                                                    </div>
                                                    <h5>Uploaded Date :- <span>{new Date(doc.createdAt).toLocaleDateString()}</span></h5>
                                                    <h6 className={doc.pan_card_status.trim() === "Approved" ? "verified" : "pending"}>
                                                        <i className="bi bi-check-circle-fill"></i> {doc.pan_card_status.trim()}
                                                    </h6>
                                                </div>
                                            )}

                                            {/* Driving License Document */}
                                            {doc.driving_license_front_img && (
                                                <div className="submit-box">
                                                    <h4>{doc.driving_license_document || "Driving License"}</h4>
                                                    <div className="submit-doc">
                                                        <img src={doc.driving_license_front_img} alt="Driving License Front" style={{ width: '520px', height: '280px' }}/>
                                                        {doc.driving_license_back_img && <img src={doc.driving_license_back_img} alt="Driving License Back" style={{ width: '520px', height: '280px' }}/>}
                                                    </div>
                                                    <h5>Uploaded Date :- <span>{new Date(doc.createdAt).toLocaleDateString()}</span></h5>
                                                    <h6 className={doc.driving_license_status.trim() === "Approved" ? "verified" : "pending"}>
                                                        <i className="bi bi-check-circle-fill"></i> {doc.driving_license_status.trim()}
                                                    </h6>
                                                </div>
                                            )}

                                            {/* Passport Document */}
                                            {doc.passport_front_img && (
                                                <div className="submit-box">
                                                    <h4>{doc.passport_document || "Passport"}</h4>
                                                    <div className="submit-doc">
                                                        <img src={doc.passport_front_img} alt="Passport Front" style={{ width: '520px', height: '280px' }}/>
                                                        {doc.passport_back_img && <img src={doc.passport_back_img} alt="Passport Back" style={{ width: '520px', height: '280px' }}/>}
                                                    </div>
                                                    <h5>Uploaded Date :- <span>{new Date(doc.createdAt).toLocaleDateString()}</span></h5>
                                                    <h6 className={doc.passport_status.trim() === "Approved" ? "verified" : "pending"}>
                                                        <i className="bi bi-check-circle-fill"></i> {doc.passport_status.trim()}
                                                    </h6>
                                                </div>
                                            )}

                                            {/* VISA Document */}
                                            {doc.visa_front_img && (
                                                <div className="submit-box">
                                                    <h4>{doc.visa_document || "VISA"}</h4>
                                                    <div className="submit-doc">
                                                        <img src={doc.visa_front_img} alt="VISA Front" style={{ width: '520px', height: '280px' }}/>
                                                        {doc.visa_back_img && <img src={doc.visa_back_img} alt="VISA Back" style={{ width: '520px', height: '280px' }}/>}
                                                    </div>
                                                    <h5>Uploaded Date :- <span>{new Date(doc.createdAt).toLocaleDateString()}</span></h5>
                                                    <h6 className={doc.visa_status.trim() === "Approved" ? "verified" : "pending"}>
                                                        <i className="bi bi-check-circle-fill"></i> {doc.visa_status.trim()}
                                                    </h6>
                                                </div>
                                            )}

                                            {/* Aadhaar Document */}
                                            {doc.aadhaar_front_img && (
                                                <div className="submit-box">
                                                    <h4>{doc.aadhaar_document || "Aadhaar"}</h4>
                                                    <div className="submit-doc">
                                                        <img src={doc.aadhaar_front_img} alt="Aadhaar Front" style={{ width: '520px', height: '280px' }}/>
                                                        {doc.aadhaar_back_img && <img src={doc.aadhaar_back_img} alt="Aadhaar Back" style={{ width: '520px', height: '280px' }}/>}
                                                    </div>
                                                    <h5>Uploaded Date :- <span>{new Date(doc.createdAt).toLocaleDateString()}</span></h5>
                                                    <h6 className={doc.aadhaar_status.trim() === "Approved" ? "verified" : "pending"}>
                                                        <i className="bi bi-check-circle-fill"></i> {doc.aadhaar_status.trim()}
                                                    </h6>
                                                </div>
                                            )}

                                            {/* Bank A/c Document */}
                                            {doc.bank_ac_front_img && (
                                                <div className="submit-box">
                                                    <h4>{doc.bank_ac_document || "Bank A/C"}</h4>
                                                    <div className="submit-doc">
                                                        <img src={doc.bank_ac_front_img} alt="Bank A/C Front" style={{ width: '520px', height: '280px' }}/>
                                                        {doc.bank_ac_back_img && <img src={doc.bank_ac_back_img} alt="Bank A/C Back" style={{ width: '520px', height: '280px' }}/>}
                                                    </div>
                                                    <h5>Uploaded Date :- <span>{new Date(doc.createdAt).toLocaleDateString()}</span></h5>
                                                    <h6 className={doc.bank_ac_status.trim() === "Approved" ? "verified" : "pending"}>
                                                        <i className="bi bi-check-circle-fill"></i> {doc.bank_ac_status.trim()}
                                                    </h6>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>

                                {/* <div className="detail-btn pt-4">
                                    <button>Back</button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Documents;
