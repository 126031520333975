import React, { useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar";
import axios from "axios";
import { useAuth } from "../AuthContext";
import { Link } from "react-router-dom";

const AdminDashboard = () => {

  const [users, setUsers] = useState(0);
  const [documents, setDocuments] = useState(0);
  const { apipath } = useAuth();

  useEffect(() => {
    fetchUsers();
    fetchDocuments();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${apipath}/users/details`);
      const users = response.data;
      setUsers(users.userCount);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchDocuments = async () => {
    try {
      const response = await axios.get(`${apipath}/document/details`);
      const documents = response.data;
      setDocuments(documents.documentFilesCount);
    } catch (error) {
      console.error('Error fetching document:', error);
    }
  };

  return (
    <>
      <AdminSidebar />
      <div className="home-main-content">
        <div className="admin-banner">

<Link to="/user-details" style={{ textDecoration: 'none', color:'#000'}}>
            <div className="admin-dashbox">
              <h2>
                <i className="bi bi-person"></i> Total Users 
              </h2>
              <h3 className="pt-2">{users}</h3>
            </div></Link>

            <Link to="/documents-details" style={{ textDecoration: 'none', color:'#000'}}>
            <div className="admin-dashbox">
              <h2>
                <i class="bi bi-file-earmark-bar-graph"></i>Total Documents 
              </h2>
              <h3>{documents}</h3>
            </div>
            </Link>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;