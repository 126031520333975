import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import iziToast from "izitoast";
import { Link } from 'react-router-dom'
import { useAuth } from '../AuthContext';
const Forget = () => {
    const { apipath } = useAuth();
    const [otp, setOtp] = useState("");

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: "",
        otp: "",
        password: "",
        confirm_password: "",
    });

    const [passwordVisible, setPasswordVisible] = useState(false);
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const [passwordVisiblenew, setPasswordVisiblenew] = useState(false);
    const togglePasswordVisibilitynew = () => {
        setPasswordVisiblenew(!passwordVisiblenew);
    };

    const [passwordVisibleconf, setPasswordVisibleconf] = useState(false);
    const togglePasswordVisibilityconf = () => {
        setPasswordVisibleconf(!passwordVisibleconf);
    };

    const handleOTP = async (event) => {
        event.preventDefault();
        document.getElementById('otpBtn').setAttribute('disabled', 'disabled');

        try {
            const response = await fetch(
                apipath + "/sendotp",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(formData)
                }
            );

            const result = await response.json();
            // console.log(result);

            if (result.success === true) {
                setOtp(result.otp);
                iziToast.success({
                    message: "OTP Sent on Email",
                    position: "topCenter"
                });
                document.getElementById('email').setAttribute('disabled', 'disabled');
                document.getElementById('otpBox').style.display = "block";
                document.getElementById('otpBtn').style.display = "none";
                document.getElementById('verifyBtn').style.display = "block";

            } else {
                document.getElementById('otpBtn').removeAttribute('disabled');
                iziToast.error({
                    message: result.error,
                    position: "topCenter"
                });
                console.error("Send OTP Failed:", result.error);
            }
        } catch (error) {
            document.getElementById('otpBtn').removeAttribute('disabled');
            iziToast.error({
                message: "Error during sending otp mail",
                position: "topCenter"
            });
            console.error("Error during sending otp mail:", error);
        }
    };

    const handleOTPVerify = async (event) => {
        event.preventDefault();
        // console.log(otp);
        // console.log(formData.otp);
        if (otp == formData.otp) {
            iziToast.success({
                message: "OTP Verified.",
                position: "topCenter"
            });
            document.getElementById('verifyBtn').style.display = "none";
            document.getElementById('mailBox').style.display = "none";
            document.getElementById('passBox').style.display = "block";
            document.getElementById('submitBtn').style.display = "block";
        }
        else {
            iziToast.error({
                message: "Please Enter Valid OTP!",
                position: "topCenter"
            });
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if(formData.password !== formData.confirm_password){
            iziToast.error({
                message: "Password do not match!",
                position: "topCenter"
            });
            return false;
        }

        try {
            const response = await fetch(
                apipath + "/resetPassword",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(formData)
                }
            );

            const result = await response.json();
            // console.log(result);

            if (result.success === true) {
                iziToast.success({
                    message: "Password Reset Successful",
                    position: "topCenter"
                });
                navigate("/");
            } else {
                iziToast.error({
                    message: "Password Reset Failed : " + result.error,
                    position: "topCenter"
                });
                console.error("Password Reset Failed:", result.error);
            }
        } catch (error) {
            iziToast.error({
                message: "Error during password reset",
                position: "topCenter"
            });
            console.error("Error during password reset:", error);
        }
    };

    const handleChange = (event) => {
        const { name, value, type } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row login-row justify-content-center">
                    <div className="col-lg-6 login-left-column">
                        <div className="">
                            <img src="images/logo.png" width={150} height={38} alt="" />
                            <h4>Few click away on <br /> KYC.</h4>
                            <p>Start your KYC in minutes.  <br />
                                save time and money</p>
                            <div className="row justify-content-center">
                                <div className="login-img col-lg-6">
                                    <img src="images/login.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="right-column">
                                    <h4>Forget Password <img src="images/hand.png" alt="" /> </h4>
                                    <p>Thank you for get back to CKYC.</p>
                                    <form action="" onSubmit={handleSubmit}>
                                        <div id="mailBox">
                                            <div className="mb-3">
                                                <label htmlFor="">Email</label>
                                                <input type="email" required id="email" name="email" onChange={handleChange} className="form-control" placeholder="Enter Email" />
                                            </div>
                                            <div className="mb-3" style={{ "display": "none" }} id="otpBox">
                                                <label htmlFor="">Email OTP</label>
                                                <div className="password-input">
                                                    <input
                                                        type={passwordVisible ? "text" : "password"}
                                                        className="form-control"
                                                        placeholder=""
                                                        name="otp"
                                                        required
                                                        onChange={handleChange}
                                                    />
                                                    <i
                                                        className={`bi bi-eye${passwordVisible ? "-slash" : ""}`}
                                                        onClick={togglePasswordVisibility}
                                                        style={{ cursor: "pointer" }}
                                                    ></i>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ "display": "none" }} id="passBox">
                                            <div className="mb-3">
                                                <label htmlFor="">New Password</label>
                                                <div className="password-input mb-3">
                                                    <input
                                                        type={passwordVisiblenew ? "text" : "password"}
                                                        className="form-control"
                                                        placeholder=""
                                                        name="password"
                                                        required
                                                        onChange={handleChange}
                                                    />
                                                    <i
                                                        className={`bi bi-eye${passwordVisiblenew ? "-slash" : ""}`}
                                                        onClick={togglePasswordVisibilitynew}
                                                        style={{ cursor: "pointer" }}
                                                    ></i>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="">Confirm Password</label>
                                                <div className="password-input mb-3">
                                                    <input
                                                        type={passwordVisibleconf ? "text" : "password"}
                                                        className="form-control"
                                                        placeholder=""
                                                        name="confirm_password"
                                                        required
                                                        onChange={handleChange}
                                                    />
                                                    <i
                                                        className={`bi bi-eye${passwordVisibleconf ? "-slash" : ""}`}
                                                        onClick={togglePasswordVisibilityconf}
                                                        style={{ cursor: "pointer" }}
                                                    ></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="submit-box">
                                            <button type="button" className="btn" onClick={handleOTP} id="otpBtn">Send OTP</button>
                                            <button type="button" className="btn" onClick={handleOTPVerify} style={{ 'display': 'none' }} id="verifyBtn">Verify OTP</button>
                                            <button className="btn" style={{ 'display': 'none' }} id="submitBtn">Submit</button>
                                            <h6 className="pt-4">Don’t have an account? <Link className="link" to="/register">Sign up</Link> </h6>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Forget;