import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import axios from "axios";
import iziToast from "izitoast";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";

const Profile = () => {
    const navigate = useNavigate();
    const { apipath, filepath, logout } = useAuth();
    const token = localStorage.getItem('token');
    const [userData, setUserdata] = useState({
        email: '',
        phone: '',
        company_name: '',
        website: '',
        profile_pic: '',
    });

    const getProfile = async () => {
        try {
            const response = await axios.get(
                `${apipath}/user/profile`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const result = response.data;
            // console.log(result);
            if (result.message == "Unauthorized") {
                logout();
            }
            else {
                setUserdata(prevState => ({
                    ...prevState,
                    email: result.user.email || prevState.email,
                    phone: result.user.phone || prevState.phone,
                    company_name: result.user.company_name || prevState.company_name,
                    website: result.user.website || prevState.website,
                    profile_pic: result.user.profile_pic || prevState.profile_pic,
                }));
            }
        } catch (error) {
            // console.log(error);
        }
    };
    useEffect(() => {
        getProfile();
    }, []);

    const handleChange = (event) => {
        const { name, value, type } = event.target;
        setUserdata((prevData) => ({
            ...prevData,
            [name]: type === "file" ? document.getElementById('fileInput').files[0] : value,
        }));
    };

    const handleUploadButtonClick = () => {
        // Trigger click event on the input file element
        document.getElementById('fileInput').click();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        // console.log(userData);
        const file = document.getElementById('fileInput').files[0];
        const formdata = new FormData();
        formdata.append('company_name', userData.company_name);
        formdata.append('email', userData.email);
        formdata.append('phone', userData.phone);
        formdata.append('website', userData.website);
        formdata.append('file', file);
        try {
            const response = await fetch(
                apipath + "/user/updateProfile",
                {
                    method: "POST",
                    headers: {
                        "Authorization": "Bearer " + token,
                    },
                    body: formdata
                }
            );

            const result = await response.json();
            // console.log(result);
            if (result.success === true) {
                iziToast.success({
                    message: "Profile Updated.",
                    position: "topCenter"
                });
                getProfile();
            } else {
                iziToast.error({
                    message: "Update failed : " + result.error,
                    position: "topCenter"
                });
                console.error("Update failed:", result.error);
            }
        } catch (error) {
            iziToast.error({
                message: "Error during update",
                position: "topCenter"
            });
            console.error("Error during update:", error);
        }
    };

    return (
        <>
            <Sidebar />

            <div className="home-main-content">

                <div className="container-fluid pt-4">
                    <div className="row">
                        <div className="col-lg-11">
                            <div className="upload-column">
                                <div>
                                    <h5>Profile</h5>
                                    <p>This is your Profile you can view edit your details here.</p>
                                </div>

                                <form className="row profile-box" onSubmit={handleSubmit}>
                                    <div className="change-profile pt-4 pb-4">
                                        <img src={userData.profile_pic !== '' ? filepath + userData.profile_pic : "images/profile.png"} width={70} height={70} style={{"border-radius":"35px","cursor":"pointer"}} alt="profile-img" onClick={handleUploadButtonClick} />
                                        <h6 style={{"cursor":"pointer"}} onClick={handleUploadButtonClick}>Change Profile</h6>
                                        <input hidden type="file" id="fileInput" name="image" onChange={handleChange} style={{ display: 'none' }} />
                                    </div>
                                    <div className="mb-3 col-lg-6">
                                        <label htmlFor="">Company Name</label>
                                        <input type="text" className="form-control" name="company_name" required onChange={handleChange} maxLength={"60"} placeholder="xxx@gmail.com" value={userData.company_name} />
                                        <i class="bi bi-envelope"></i>
                                    </div>
                                    <div className="mb-3 col-lg-6">
                                        <label htmlFor="">Email</label>
                                        <input type="email" className="form-control" name="email" required onChange={handleChange} placeholder="xxx@gmail.com" value={userData.email} maxLength={"60"} />
                                        <i class="bi bi-buildings"></i>
                                    </div>
                                    <div className="mb-3 col-lg-6">
                                        <label htmlFor="">Phone Number</label>
                                        <input type="text" className="form-control" name="phone" required onChange={handleChange} pattern="\d*" placeholder="000 000 0000" maxLength={"10"} value={userData.phone} />
                                        <i class="bi bi-telephone"></i>
                                    </div>
                                    <div className="mb-3 col-lg-6">
                                        <label htmlFor="">Website</label>
                                        <input type="text" className="form-control" name="website" required onChange={handleChange} placeholder="xxx@gmail.com" value={userData.website} />
                                        <i class="bi bi-globe"></i>
                                    </div>
                                    {/* <div className="mb-3 col-lg-6">
                                        <label htmlFor="">Password</label>
                                        <input type="password" className="form-control" />
                                        <i class="bi bi-person-lock"></i>
                                    </div> */}
                                    <div className="detail-btn pt-4">
                                        <button>Save Changes</button>
                                    </div>
                                </form>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Profile;