import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import axios from "axios";
import iziToast from "izitoast";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";

const Uploaddetails = () => {
    const navigate = useNavigate();
    const { apipath, filepath, logout } = useAuth();
    const token = localStorage.getItem('token');
    const uploadDoc = JSON.parse(localStorage.getItem('uploadDoc'));
    const upload_response = JSON.parse(localStorage.getItem('upload_response'));
    // console.log(upload_response);
    const doc = uploadDoc.document;
    const btoken = "LTE5MDQwMDQ0ODM=_sk5pb23h7acyhxqom001e";
    const [docfields, setDocFields] = useState();
    const [batchId, setBatchid] = useState('');
    const [extractionId, setExtractionId] = useState('');
    const [upfile, setUpfile] = useState();
    const [fields, setFields] = useState();

    const getResults = async () => {

        const url = "https://api.extracta.ai/api/v1/getBatchResults";
        const fdata = {
            'extractionId': upload_response.extractionId,
            'batchId': upload_response.batchId,
        }
        const response = await axios.post(url, fdata, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${btoken}`
            }
        });
        const result = response.data;
        console.log(response.data);
        setBatchid(result.batchId);
        setExtractionId(result.extractionId);
        if(result.finished === false){
            setTimeout(()=>{
                getResults();
            }, 2000);
        }
        else{
            if (result.files.length === 1) {
                setUpfile({
                    'file_id': result.files[0].fileId,
                    'file_name': result.files[0].fileName,
                    'file_url': result.files[0].url,
                });
                setFields(result.files[0].result);
            }
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        var updata = {
            'document_name' : doc,
            'document_files': JSON.stringify(upfile),
            'document_fields': JSON.stringify(fields),
            'extractionId': extractionId,
            'batchId': batchId,
            'company_type': uploadDoc.companytype,
        }
        try {
            const response = await fetch(
                apipath + "/user/uploadDocument",
                {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": "Bearer " + token,
                    },
                    body: JSON.stringify(updata)
                }
            );

            const result = await response.json();
            console.log(result);
            if (result.success === true) {
                iziToast.success({
                    message: "Document Uploaded.",
                    position: "topCenter"
                });
                // navigate('/documents');
            } else {
                iziToast.error({
                    message: "Upload failed : " + result.error,
                    position: "topCenter"
                });
                console.error("Upload failed:", result.error);
            }
        } catch (error) {
            iziToast.error({
                message: "Error during uploading",
                position: "topCenter"
            });
            console.error("Error during uploading : ", error);
        }
    }

    useEffect(() => {
        if (upload_response) {
            getResults();
        }
        else{
            navigate('/kyc');
        }
    }, []);

    const handleChange = (event) => {
        const { name, value, type } = event.target;
        setFields((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    useEffect(() => {
        console.log(fields);
    }, [fields]);

    if (fields) {
        var formfields = Object.entries(fields).map(([key, value]) => (
            <>
                <div className="mb-3 col-lg-4">
                    <label>{key}</label>
                    <input type="text" className="form-control" onChange={handleChange} name={key} placeholder={key} value={value} />
                </div>
            </>
        ));
    }
    else {
        formfields = "";
    }

    if (upfile) {
        var filefield = <>
            <div className="mb-3 col-lg-4">
                <label>{doc} File</label><br></br>
                <img src={upfile.file_url} width="150px" />
                <a href={upfile.file_url} target="_blank" download={upfile.file_name}>Download</a>
            </div>
        </>
    }
    else {
        filefield = "";
    }


    return (
        <>
            <Sidebar />

            <div className="home-main-content">

                <div className="container-fluid pt-4">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="upload-column">
                                <div>
                                    <h5>{doc} Details</h5>
                                    <p>Below are your details of your {doc} you
                                        submitted</p>
                                </div>

                                <form onSubmit={handleSubmit}>
                                    <div className="row detail-form-row pt-4">
                                        {formfields}
                                        {filefield}
                                        {/* <div className="mb-3 col-lg-4">
                                        <label>Name</label>
                                        <input type="text" className="form-control" placeholder="Akash Rawat" />
                                    </div>
                                    <div className="mb-3 col-lg-4">
                                        <label>Father's Name</label>
                                        <input type="text" className="form-control" placeholder="Akash Rawat" />
                                    </div>
                                    <div className="mb-3 col-lg-4">
                                        <label>Date of Birth</label>
                                        <input type="date" className="form-control" />
                                    </div>
                                    <div className="mb-3 col-lg-4">
                                        <label>Gender</label>
                                        <input type="text" className="form-control" placeholder="Male" />
                                    </div>
                                    <div className="mb-3 col-lg-4">
                                        <label>Aadhar Number</label>
                                        <input type="number" className="form-control" />
                                    </div>
                                    <div className="col-lg-4"></div>
                                    <div className="mb-3 col-lg-4">
                                        <label>Address</label>
                                        <textarea className="form-control" placeholder="Shivaji colony, Kotdwara" />
                                    </div> */}
                                    </div>

                                    <div className="detail-btn pt-4">
                                        <button>Confirm & Next</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    );
};

export default Uploaddetails;